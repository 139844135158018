<template>
  <div>
    <router-view/>
  </div>
</template>

<script>

export default {
  name: 'agentUserList',
  components: {
  },
  data() {
    return {};
  },
};
</script>

<style>
</style>
